#header_top {
	border-top: 14px solid $color__brown_dark;
	#secondary_navigation {
		max-width: $global_width;
		margin: 16px auto 0;
		flex: 1 0 auto;
		justify-content: flex-end;
		display: flex;
		@include font_size_xxxs;
		a {
			font-weight: 400;
			line-height: 24px;
			letter-spacing: 0.1em;
			text-transform: uppercase;
			margin: 0 0 0 48px;
			padding: 0;
			color: $color__brown_dark;
			&:hover {
				color: $color__black;
			}
		}

		@include media_query(large) {
			margin-left: $body_margin;
			margin-right: $body_margin;
		}
	}

	@include media_query(medium) {
		display: none;
	}
}

#header_bottom {
	max-width: $global_width;
	margin: 64px auto 80px;
	padding: 0 0 28px;
	display: flex;
	align-items: flex-end;
	border-bottom: 0.5px solid #683610;
	.makar_logo {
		z-index: 100000;
		flex: 0 1 auto;
		width: 372px;
		height: 119px;

		@include media_query(medium_large) {
			width: 248px;
			height: 80px;
		}

		@include media_query(medium) {
			width: 146px;
			height: 47px;
		}
	}
	#menu_btn {
		display: none;
		z-index: 100000;
		width: 40px;
		height: 40px;
		position: absolute;
		top: 20px;
		right: 28px;
		background-image: url($images_dir + 'menu_open.svg');
		background-position: center;
		cursor: pointer;
		body.menu_open & {
			position: fixed;
			background-image: url($images_dir + 'menu_close.svg');
		}

		@include media_query(medium) {
			display: block;
		}
	}
	#primary_navigation {
		flex: 1 0 auto;
		justify-content: flex-end;
		display: flex;
		@include font_size_xs;
		a {
			line-height: 24px;
			letter-spacing: 0.1em;
			text-transform: uppercase;
			margin: 0 0 0 56px;
			padding: 0;
			&:hover {
				color: $color__brown_dark;
			}

			// @include media_query(large) {
			// 	margin: 0 0 0 40px;
			// }

			@include media_query(medium) {
				display: block;
				margin: 0 0 48px;
				color: $color__brown_dark;
				&:hover {
					color: $color__black;
				}
			}
		}

		#secondary_navigation_mobile {
			display: none;
			@include media_query(medium) {
				display: block;
			}
		}

		@include media_query(medium) {
			display: block;
			padding: 120px $body_margin $body_margin;
			z-index: 10000;
			background-color: $color__brown_light;
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			transition: .25s opacity;
			pointer-events: none;
			body.menu_open & {
				opacity: 1;
				pointer-events: all;
			}
		}
	}

	@include media_query(large) {
		margin-left: $body_margin;
		margin-right: $body_margin;
	}

	@include media_query(medium_large) {
		align-items: baseline;
	}

	@include media_query(medium) {
		margin: 32px $body_margin 36px;
	}

	@include media_query(small) {
		margin-bottom: 12px;
		padding: 0 0 16px;
	}
}
