input[type=color],
input[type=date],
input[type=datetime-local],
input[type=datetime],
input[type=email],
input[type=month],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=text],
input[type=time],
input[type=url],
input[type=week],
input[type=file],
textarea {
	margin: 12px 0 40px;
	padding: 12px 12px;
	font-size: 22px;
	line-height: 28px;
	box-sizing: border-box;
	display: block;
	appearance: none;
	border: 0 none;
	background-color: $color__brown_light;
	height: 60px;
	width: 100%;
	&:active,
	&:focus {
		background-color: mix($color__brown_dark, $color__white, 20);
	}

	@include media_query(small) {
		margin: 4px 0 30px;
		padding: 8px 6px;
		height: 35px;
		font-size: 14px;
		line-height: 18px;

	}
}

textarea {
	height: auto;
	resize: none;
}

label {
	display: block;
	font-size: 18px;
	line-height: 21px;
	color: $color__brown_dark;
	letter-spacing: 0.1em;
	&.required {
		&:after {
			content: "*";
		}
	}

	@include media_query(small) {
		font-size: 14px;
		line-height: 18px;
	}
}

button,
.button,
[type=submit] {
	background-color: transparent;
	display: inline-block;
	box-sizing: border-box;
	height: 68px;
	padding: 16px 56px 16px 28px;
	border: 1.5px solid $color__brown_dark;
	@include small_caps;
	color: $color__brown_dark;
	font-weight: $font__regular;
	background-image: url($images_dir + 'chevron_right/medium_brown.svg');
	background-position: right 24px top 48%;
	cursor: pointer;
	&:hover {
		background-color: $color__brown_light;
	}

	@include media_query(small) {
		height: 24px;
		padding: 3px 24px 6px 8px;
		border-width: 1px;
		background-position: right 10px top 48%;
		background-size: 8px;
		background-color: $color__brown_light;
	}
}

.submit {
	margin: 32px 0 0;
}

.label_wrapper {
	line-height: 0;
	label {
		display: inline-block;
	}
	.detail {
		display: inline-block;
		font-size: 12px;
		line-height: 21px;
		letter-spacing: 0.1em;
		color: $color__brown_dark;
	}
}

.switch_fields {
	display: flex;
	.field {
		width: 120px;
		+ .field {
			label {
				border-left: 0 none !important;
				border-right: .5px solid $color__brown_dark;
			}
		}
		label {
			margin: 0;
			height: 68px;
			padding: 18px 0;
			text-align: center;
			@include small_caps;
			color: $color__brown_dark;
			font-weight: $font__regular;
			border: .5px solid $color__brown_dark;
			border-right: 0 none;
			cursor: pointer;
			transition: .25s background-color;

			@include media_query(small) {
				height: 24px;
				padding: 3px 0;
			}
		}
		input {
			position: absolute;
			opacity: 0;
			&:checked + label {
				background-color: $color__brown_light;
			}
		}

		@include media_query(small) {
			width: 48px;
		}
	}
}

.form_fields {
	display: flex;
	margin: 0 -60px;
	.column {
		width: calc(50% - 60px);
		margin: 0 60px;
	}

	@include media_query(medium) {
		display: block;
		margin: 0;
		.column {
			width: 100%;
			margin: 0;
		}
	}
}

// NB: unused as yet
.two_columns {
	column-count: 2;
	column-gap: 60px;
	column-fill: balance;
	> * {
		break-inside: avoid;
	}
}

.fieldgroup_checkboxes,
.fieldgroup_radios {
	display: flex;
	flex-wrap: wrap;
	margin: 12px 0 27px;
	.field_checkbox,
	.field_radio {
		position: relative;
		margin: 0 32px 16px 0;
		label {
			position: relative;
			font-size: 20px;
			line-height: 28.8px;
			letter-spacing: normal;
			color: $color__black;
			cursor: pointer;
			padding: 0 0 0 40px;
			&:before {
				position: absolute;
				left: 0;
				top: 0;
				content: " ";
				display: inline-block;
				width: 30px;
				height: 30px;
				background-color: $color__brown_light;
				vertical-align: middle;

				@include media_query(small) {
					width: 20px;
					height: 20px;
				}
			}
			&:hover {
				&:before {
					background-color: mix($color__brown_dark, $color__white, 20);
				}
			}

			@include media_query(small) {
				padding: 0 0 0 25px;
				font-size: 16px;
				line-height: 19px;
			}
		}
		input {
			position: absolute;
			opacity: 0;
		}

		@include media_query(small) {
			margin: 0 16px 12px 0;
		}
	}

	@include media_query(small) {
		margin: 8px 0 20px;
	}
}

.fieldgroup_checkboxes {
	.field_checkbox {
		input {
			&:checked + label {
				&:before {
					background-image: url($images_dir + 'tick.svg');
					background-repeat: no-repeat;
					background-position: center;

					@include media_query(small) {
						background-size: 12px;
					}
				}
			}
		}
	}
}

.fieldgroup_radios {
	.field_radio {
		label {
			&:before {
				border-radius: 50%;
			}
		}
		input {
			&:checked + label {
				&:after {
					position: absolute;
					top: 7px;
					left: 7px;
					content: " ";
					display: inline-block;
					width: 16px;
					height: 16px;
					border-radius: 50%;
					background-color: $color__brown_dark;

					@include media_query(small) {
						top: 4px;
						left: 4px;
						width: 12px;
						height: 12px;
					}
				}
			}
		}
	}
}

.ff-form-errors {
	margin: 0 0 40px;
	color: $color__red;
}
.ff-has-errors {
	border: 1px solid $color__red !important;
}
.ff-errors {
	min-height: 0.001px;
	display: block;
	color: $color__red;
	margin: -24px 0 24px;
	padding: 0;
	list-style: none;
	@include font_size_xs;
	font-weight: $font__regular;
}
