.vacancies {
	.vacancy {
		margin: 0 0 160px;
		h2 {
			@include font_size_l;
			margin: 0 0 24px;

			@include media_query(small) {
				margin: 0 0 16px;
			}
		}
		.intro {}
		.button {
			margin: 56px 0 24px;

			@include media_query(small) {
				margin: 24px 0 16px;
			}
		}
		.closing_date {
			letter-spacing: 0.05em;
			color: $color__brown_dark;
		}
	}
	.message {
		font-weight: $font__regular;
		color: $color__brown_dark;
		@include font_size_m;
	}
}

body.type_vacancy {
	.application_details {
		@include font_size_m;
		margin: 0 0 32px;

		@include media_query(small) {
			margin: 0 0 24px;
		}
	}
	.closing_date {
		letter-spacing: 0.1em;
		color: $color__brown_dark;
	}
}
