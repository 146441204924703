#hero_image {
	max-width: $global_width;
	margin: 0 auto 60px;
	img {
		display: block;
		width: 100%;
	}
	#breadcrumbs + & {
		margin-top: -96px;

		@include media_query(medium) {
			margin-top: -56px;
		}

		@include media_query(medium) {
			margin-top: -14px;
		}
	}
	&.full_width {
		margin: 0 0 60px;
		max-width: none;
		height: 736px;
		background-position: center;
		background-size: cover;
		body.section_home & {
			height: 880px;

			@include media_query(large) {
				height: 0;
				padding-top: 56.25%;
			}
		}
		&.video {
			position: relative;
			pointer-events: none;
			overflow: hidden;
			.responsive_embed.ratio_16_9 {
				position: absolute;
				width: 100%;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				iframe {
					width: 100%;
					height: 100%;
					position: absolute;
				}

				@include media_query(1564px) {
					height: 100%;
					width: 0;
					padding-bottom: 0;
					padding-left: 177.77%;
				}
			}
		}
	}

	@include media_query(large) {
		margin: 0 $body_margin;
	}

	@include media_query(large) {
		margin-top: 24px;
	}
}
