.thumb {
	display: block;
	position: relative;
	margin: 0 0 24px;
	img {
		width: 100%;
	}
	.title {
		display: block;
		width: 100%;
		letter-spacing: 0.05em;
		color: $color__brown_dark;
		background-image: url($images_dir + 'chevron_right/medium_brown_thin.svg');
		background-position: right 0 top 6px;
		transition: .25s color;
		letter-spacing: 0.05em;

		@include media_query(small) {
			background-size: 7px 7px;
			background-position: right 0 top 6px;
			letter-spacing: 0.1em;
		}
	}
	&.large {}
	&.medium {}
	&.small {}
	&.portrait {
		padding-top: 137.79%;
		background-position: center;
		background-size: cover;
		.overlay {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			background-image: linear-gradient(to bottom, rgba(#00020F, 0), rgba($color__black, 1));
			transition: .25s opacity;
		}
		.title {
			position: absolute;
			bottom: 0;
			@include font_size_l;
			color: $color__white;
			padding: 0 60px 60px 40px;
			background-image: url($images_dir + 'chevron_right/large_white.svg');
			background-position: right 40px bottom 64px;
			letter-spacing: 0;

			@include media_query(small) {
				padding: 0 30px 32px 20px;
				background-size: 12px 12px;
				background-position: right 24px bottom 32px;
			}
		}
		&:hover {
			.overlay {
				opacity: 0.75;
			}
		}
		&.no_image {
			border: 1.5px solid $color__brown_dark;
			padding-top: calc(137.79% - 3px);
			.title {
				padding-bottom: 58.5px;
				color: $color__brown_dark;
				background-image: url($images_dir + 'chevron_right/large_brown.svg');
				background-position: right 40px bottom 62.5px;

				@include media_query(small) {
					padding-bottom: 32px;
					background-position: right 24px bottom 32px;
				}
			}
			&:hover {
				background-color: $color__brown_light;
			}

			@include media_query(small) {
				border-width: 1px;
			}
		}

		@include media_query(small) {
			padding-top: 82.18% !important;
		}
	}

	@include media_query(small) {
		margin: 0 0 8px;
	}
}
