.home_block {
	max-width: $global_width;
	margin: 0 auto;
	padding: 140px 0 0;
	h2 {
		border-top: 0.5px solid $color__brown_dark;
		max-width: $global_width_narrow;
		margin: -120px auto 94px;
		color: $color__brown_dark;
		letter-spacing: 0.05em;
		padding: 24px 0 0;

		@include media_query(large) {
			margin: -96px 0 80px;
		}

		@include media_query(small) {
			margin: -52px 0 36px;
		}
	}
	&.portfolio {
		margin-bottom: 16px;
		h2 {
			margin-bottom: 16px;
		}
	}
	hr {
		border: 0 none;
		border-top: 0.5px solid $color__brown_dark;
		max-width: $global_width_narrow;
		margin: -72px auto 96px;
	}

	@include media_query(large) {
		margin: 0 $body_margin;
		padding: 120px 0 0;
	}

	@include media_query(small) {
		margin: 0 $body_margin;
		padding: 60px 0 0;
	}
}
