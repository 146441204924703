// Image path
$images_dir: "../img/";

// Colours
$color__white: #FFF;
$color__black: #000;
$color__red: #DD3333;
$color__brown_dark: #5D3100;
$color__brown_light: #EDEAE5;

// Sizes
$global_width: 1360px;
$global_width_narrow: 1088px;
$body_margin: 36px;

// Font weights
$font__light: 300;
$font__regular: 400;
$font__bold: 700;
