#footer {
	max-width: $global_width;
	margin: 160px auto 85px;
	padding: 28px 0 0;
	display: flex;
	border-top: 1px solid $color__brown_dark;
	font-weight: $font__regular;
	#footer_left {
		flex: 1 0 auto;
		.makar_logo {
			width: 258px;
			height: 83px;
			display: inline-block;

			@include media_query(medium) {
				width: 194px;
				height: 62px;
			}

			@include media_query(small) {
				width: 105px;
				height: 34px;
			}
		}
	}
	#footer_right {
		font-size: 14px;
		line-height: 17px;
		letter-spacing: 0.02px;
		#footer_address {
			margin: 0 0 40px;
			strong {
				font-size: 16px;

				@include media_query(small) {
					font-size: 12px;
				}
			}

			@include media_query(small_medium) {
				margin: 20px 0 8px;
			}
		}
		#footer_contact {
			margin: 0 0 18px;
			a {
				&:hover {
					text-decoration: underline;
				}
			}
		}
		#social_links {
			display: flex;
			.social_icon {
				height: 24px;
				margin: 0 20px 0 0;
				background-position: center;
				&:hover {
					opacity: 0.75;
				}
				&.instagram {
					background-image: url($images_dir + 'social/instagram.svg');
					width: 23px;
				}
				&.facebook {
					background-image: url($images_dir + 'social/facebook.svg');
					width: 12px;
				}
				&.twitter {
					background-image: url($images_dir + 'social/twitter.svg');
					width: 24px;
				}
			}
		}
		#footer_navigation {
			margin: 56px 0 0;
			font-size: 12px;
			line-height: 20px;
			display: flex;
			justify-content: space-between;
			a {
				&:hover {
					text-decoration: underline;
				}
			}

			@include media_query(small) {
				font-size: 10px;
				margin: 16px 0 0;
			}
		}

		@include media_query(small) {
			font-size: 10px;
		}
	}

	@include media_query(large) {
		margin: 160px $body_margin 85px;
	}

	@include media_query(small_medium) {
		display: block;
		margin: 130px $body_margin 37px;
	}

	@include media_query(small_medium) {
		margin-top: 80px;
		padding: 26px 0 0;
	}
}
