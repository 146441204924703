.carousel_wrapper {
	overflow: hidden;
	margin: 0 0 64px;
	img {
		width: 100%;
		pointer-events: none;
	}
	.carousel {
		transition: .5s transform;
		display: flex;
		position: relative;
		.image {
			flex: 1 0 auto;
			width: 100%;
			cursor: pointer;
		}
	}
	.carousel_navigation {
		max-width: $global_width;
		margin: 32px auto 0;
		display: flex;
		.dot {
			width: 18px;
			height: 18px;
			border: 0.5px solid $color__brown_dark;
			margin: 0 12px 0 0;
			cursor: pointer;
			transition: .25s background-color;
			&:hover {
				background-color: $color__brown_light;
			}
			&.current {
				background-color: $color__brown_dark;
			}

			@include media_query(small) {
				width: 10px;
				height: 10px;
				margin: 0 6px 0 0;
			}
		}

		@include media_query(large) {
			margin: 24px $body_margin 0;
		}
	}
	&.small_carousel {
		.carousel {
			margin-left: calc(50% - #{$global_width / 2});
			.image {
				width: 900px;
				padding: 0 128px 0 0;
				.title {
					display: block;
					color: $color__brown_dark;
					letter-spacing: 0.05em;
					background-image: url($images_dir + 'chevron_right/medium_brown_thin.svg');
					background-position: right 0 top 10px;
					&:hover {
						color: $color__black;
					}

					@include media_query(small) {
						background-size: 9px;
						background-position: right 0 top 6px;
					}
				}

				@include media_query(large) {
					width: 60vw;
					padding: 0 96px 0 0;
				}

				@include media_query(medium) {
					padding: 0 48px 0 0;
				}

				@include media_query(small_medium) {
					padding: 0 16px 0 0;
				}
			}

			@include media_query(large) {
				margin-left: $body_margin;
			}
		}
		.carousel_navigation {
			justify-content: flex-end;
			.dot {
				margin: 0 0 0 12px;

				@include media_query(small) {
					margin: 0 0 0 6px;
				}
			}
		}
	}
	&.drag {
		.carousel {
			transition: none;
		}
	}
}
