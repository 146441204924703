#portfolio_view {
	margin: 0 0 60px;

	@include media_query(small) {
		margin: 0 0 24px;
	}
}

#project_category_filters {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -28px 40px;
	.project_category {
		white-space: nowrap;
		margin: 0 28px 28px;
		cursor: pointer;
		&.selected {
			font-weight: $font__regular;
		}

		@include media_query(small) {
			display: block;
			margin: 0 0 10px;
		}
	}

	@include media_query(small) {
		display: block;
		margin: 0 0 32px;
	}
}

#grid_view,
#map_view {
	display: none;
	&.show {
		display: block;
	}
}

#grid_view {
	.thumb {
		* {
			pointer-events: none;
		}
	}
}

#map_view {
	#projects_map {
		background-color: $color__brown_light;
		height: 2020px;

		@include media_query(medium) {
			height: 600px;
		}
	}
}
