.accordions {
	.accordion {
		margin: 0 0 32px;
		&.open {
			.accordion_title {
				&:before {
					background-image: url($images_dir + 'minus.svg');
				}
			}
			.accordion_content {
				display: block;
			}
		}
		.accordion_title {
			position: relative;
			@include font_size_l;
			padding: 0 0 0 48px;
			margin: 0 0 16px;
			cursor: pointer;
			transition: .25s color;
			&:hover {
				color: $color__brown_dark;
			}
			&:before {
				position: absolute;
				content: " ";
				display: block;
				width: 25px;
				height: 25px;
				left: 0;
				top: 16px;
				background-image: url($images_dir + 'plus.svg');
				background-position: center;
				background-size: contain;
				background-repeat: no-repeat;

				@include media_query(medium) {
					top: 9px;
				}

				@include media_query(small) {
					width: 14px;
					height: 14px;
					top: 7px;
				}
			}

			@include media_query(small) {
				padding: 0 0 0 24px;
				margin: 0 0 8px;
			}
		}
		.accordion_content {
			height: 0;
			transition: .25s height;
			overflow: hidden;
			.inner {
				padding: 12px 0;
				.resource {
					margin: 32px 0;
				}
			}
		}

		@include media_query(small) {
			margin: 0 0 16px;
		}
	}
}
