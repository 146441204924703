$breakpoints: (
    large: $global_width + ($body_margin * 2), // 1432px
    medium_large: 1248px,
    medium: 1000px,
    small_medium: 800px,
    small: 600px,
);

@mixin media_query($size) {
    // If it's a predefined size (e.g. 's'), use that, otherwise use the specified size (e.g. 1000px)
    $breakpoint: '';
    @if (map-get($breakpoints, $size)) {
        $breakpoint: map-get($breakpoints, $size);
    } @else {
        $breakpoint: $size;
    }

    @media screen and (max-width: $breakpoint) {
        @content;
    }
}
