#body {
	max-width: $global_width_narrow;
	margin: 40px auto 0;

	@include media_query(large) {
		margin: 0 $body_margin;
	}

	// Matrix
	.block {
		margin: 48px 0;
		&:first-of-type {
			margin-top: 0;
		}
		&_enquiry {
			margin-top: 80px;
			h2 {
				@include font_size_l;
				margin: 0 0 24px;
			}
			.button {
				margin: 16px 0 0;
			}

			@include media_query(small) {
				margin: 56px 0 !important;
				h2 {
					margin: 0 0 8px;
				}
				p {
					margin-top: 8px;
				}
				.button {
					margin: 6px 0 0;
				}
			}
		}
		&_image_and_text {
			.image_and_text {
				display: flex;
				align-items: center;
				.image {
					flex: 1 0 auto;
					width: 674px;
					margin: 0 0 24px;
					img {
						display: block;
						width: 100%;
					}

					@include media_query(large) {
						width: 50%;
					}
				}
				&.image_position_left {
					.image {
						margin-right: 128px;

						@include media_query(large) {
							margin-right: 64px;
						}
					}
				}
				&.image_position_right {
					flex-direction: row-reverse;
					.image {
						margin-left: 128px;

						@include media_query(large) {
							margin-left: 64px;
						}
					}
				}

				@include media_query(medium) {
					display: block;
					.image {
						width: 100%;
						margin: 0 0 40px !important;
					}
				}
			}
		}
		&_quote {
			blockquote {
				margin: 0;
				p {
					display: block;
					margin: 0;
					font-size: 30px;
					line-height: 40px;
					font-style: italic;
					&:before {
						content: "“";
					}
					&:after {
						content: "”";
					}

					@include media_query(small) {
						font-size: 21px;
						line-height: 28px;
					}
				}
				cite {
					display: block;
					margin: 30px 0 0;
					color: $color__brown_dark;
					font-style: normal;
					letter-spacing: 0.1em;

					@include media_query(small) {
						margin: 16px 0 0;
					}
				}
			}
		}
		&_text_block {
			.text_block {
				&.columns_2 {
					display: flex;
					.text_column {
						width: 50%;
						h2 {
							margin-top: 40px;
						}
						&:first-of-type {
							margin-right: 84px;
						}
						&:last-of-type {
							margin-left: 84px;
						}
					}

					@include media_query(large) {
						.text_column {
							&:first-of-type {
								margin-right: 40px;
							}
							&:last-of-type {
								margin-left: 40px;
							}
						}
					}

					@include media_query(medium) {
						display: block;
						.text_column {
							margin: 0 !important;
							width: auto;
						}
					}
				}
			}
		}

		@include media_query(small) {
			margin: 40px 0;
		}
	}
}

.rich_text {
	& + .rich_text {
		margin-top: 24px;

		@include media_query(small) {
			margin-top: 16px;
		}
	}
	& > *:first-child {
		margin-top: 0;
	}
	p {
		margin: 18px 0 0;
	}
  h3 {
    font-size: 1.2em;
    margin-top: 1.3em;
		color: $color__brown_dark;
		font-weight: $font__regular;
  }
  h4 {
    font-weight: 500;
    margin-top: 1.3em;
  }
  figure {
    img {
      width: 100%;
      max-width: 100%;
    }
  }
	a {
		color: $color__brown_dark;
		border-bottom: 1px solid $color__brown_dark;
		&:hover {
			color: inherit;
			border-color: $color__black;
		}
	}
	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		li {
			position: relative;
			margin: 0 0 18px;
			padding: 0 0 0 14px;
			&:before {
				content: "•";
				position: absolute;
				left: 0;
			}
		}
	}
	h2 {
		@include font_size_m;
		color: $color__brown_dark;
		font-weight: $font__regular;
		margin: 48px 0 24px;

		@include media_query(small) {
			margin: 40px 0 12px;
		}
	}
}

.cta,
.download {
	margin: 32px 0;
	& + .cta,
	& + .download {
		margin-top: -16px;
	}

	@include media_query(small) {
		margin: 16px 0;
	}
}
