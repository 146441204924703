$grid_columns: 12;
$grid_margin_large: 48px;
$grid_margin_small: 28px;

.grid {
    display: flex;
    flex-wrap: wrap;
    margin: -#{$grid_margin_large/2};

    // Cell: generic settings
    .cell {
        width: calc(100% - #{$grid_margin_large});
        margin: #{$grid_margin_large / 2};
        @include media_query(small) {
            margin: #{$grid_margin_small / 2};
        }
    }

    // Cell sizes
    // Format .cell_{breakpoint}_{columns}
    // E.g. .cell_4 or .cell_medium_6
    @for $i from 1 through $grid_columns {
        $cell_width: (100 / $grid_columns) * $i;
        .cell_#{$i} {
            width: calc(#{$cell_width}% - #{$grid_margin_large});

            @include media_query(small) {
                width: calc(#{$cell_width}% - #{$grid_margin_small});
            }
        }

        @each $breakpoint, $size in $breakpoints {
            .cell_#{$breakpoint}_#{$i} {

                @if $breakpoint != small {
                    @include media_query($breakpoint) {
                        width: calc(#{$cell_width}% - #{$grid_margin_large});
                    }
                }

                @include media_query(small) {
                    width: calc(#{$cell_width}% - #{$grid_margin_small});
                }
            }
        }
    }

    @include media_query(small) {
        margin: -#{$grid_margin_small/2};
    }
}
