#project {
	max-width: $global_width;
	margin: 0 auto;
	h1 {
		@include font_size_l;
		margin: 0 0 66px;

		@include media_query(small) {
			margin: 0 0 24px;
		}
	}
	.images {
		img {
			width: 100%;
		}
	}
	.details {
		margin-top: 96px;
	}
	.modal_wrapper & {
		.images {
			margin-left: -130px;
			margin-right: -130px;
			.carousel_navigation {
				margin-left: 130px;
				margin-right: 130px;
			}
		}
	}
}
