#intro {
	max-width: $global_width_narrow;
	margin: 0 auto;
	h1 {
		@include font_size_l;
		margin: 60px 0 40px;

		@include media_query(small) {
			margin: 20px 0 14px;
		}
	}

  .intro_text {
		margin-bottom: 40px;

		@include media_query(small) {
			margin-bottom: 14px;
		}
  }

	@include media_query(large) {
		margin: 0 $body_margin;
	}
}
