#breadcrumbs {
	max-width: $global_width;
	margin: 80px auto 128px;
	ul {
		display: flex;
		flex-wrap: wrap;
		margin: 0;
		padding: 0;
		list-style: none;
		@include font_size_xs;
		color: $color__brown_dark;
		li.breadcrumb {
			a {
				&:hover {
					color: $color__black;
				}
			}
			& + li.breadcrumb {
				margin: 0 0 0 10px;
				padding: 0 0 0 32px;
				background-image: url($images_dir + 'chevron_right/small_brown.svg');
				background-position: left 0 top 60%;
				background-size: 12px 12px;

				@include media_query(small) {
					margin: 0 0 0 6px;
					padding: 0 0 0 12px;
					background-position: left 0 top 6px;
					background-size: 4px 4px;
				}
			}
		}
	}

	@include media_query(large) {
		margin: 80px $body_margin 128px;
	}

	@include media_query(medium) {
		margin: 40px $body_margin 80px;
	}

	@include media_query(small) {
		margin: 0 $body_margin 32px;
	}
}
