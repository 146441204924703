.modal_wrapper {
	background-color: rgba($color__black, 0.5);
    width: 100%;
    height: 100%;
    position: fixed;
	top: 0;
	left: 0;
	display: flex;
	z-index: 100000;
	align-items: center;
	justify-content: center;
	opacity: 0;
	pointer-events: none;
	transition: .25s opacity;
	* {
		pointer-events: none !important;
	}
	&.show {
		opacity: 1;
		pointer-events: all;
		* {
			pointer-events: all !important;
		}
	}
	.modal {
		flex: 0 1 auto;
		background-color: $color__white;
		min-width: 812px;
		max-width: $global_width;
		max-height: calc(100vh - 180px);
		padding: 70px;
		position: relative;
		overflow-y: auto;
		.close_modal_btn {
			position: absolute;
			top: 15px;
			right: 15px;
			width: 50px;
			height: 50px;
			background-image: url($images_dir + 'close.svg');
			background-position: center;
			cursor: pointer;
			&:hover {
				opacity: 0.75;
			}

			@include media_query(small_medium) {
				background-size: 14px;
				top: 0;
				right: 0;
				width: 40px;
				height: 40px;
			}
		}

		@include media_query(large) {
			min-width: 0;
			max-width: none;
			max-height: none;
			width: calc(100% - #{$body_margin * 2});
			height: calc(100vh - #{$body_margin * 2});
		}

		@include media_query(medium) {
			padding: 24px 32px;
		}
	}
	&#project_modal_wrapper {
		.modal {
			padding-left: 130px;
			padding-right: 130px;
			padding-bottom: 0;
			.content {
				padding-bottom: 70px;
			}

			@include media_query(medium) {
				padding-left: $body_margin;
				padding-right: $body_margin;
			}
		}
	}
	&#download_modal_wrapper {
		.modal {
			padding: 120px 140px 110px;

			@include media_query(medium) {
				padding: 60px 70px 55px;
			}
		}
	}
}
