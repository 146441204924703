#team_member {
	max-width: $global_width;
	margin: 0 auto 0;
	display: flex;
	.image {
		flex: 1 0 auto;
		width: 304px;
		margin: 0 60px 0 0;
		img {
			width: 100%;
		}

		@include media_query(medium) {
			width: 180px;
			margin: 0 30px 0 0;
		}

		@include media_query(small_medium) {
			width: 100%;
			margin: 0 0 24px 0;
		}
	}
	.details {
		h2 {
			margin: -8px 0 16px;
			@include font_size_l;
		}
		.qualifications {
			margin: 0 0 40px;

			@include media_query(small) {
				margin: 0 0 20px;
			}
		}
	}

	@include media_query(large) {
		margin: 8px 0 0;
	}

	@include media_query(small_medium) {
		display: block;
	}
}

.team_member_thumb {
	* {
		pointer-events: none;
	}
}
