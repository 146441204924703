// Rules to apply to all elements
html * {
    box-sizing: border-box;
    background-repeat: no-repeat;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    font-weight: inherit;
}

html {
    -webkit-font-smoothing: antialiased;
}

// Remove the annoying blue focus that seems to happen in Chrome
:focus {
    outline-color: transparent;
    outline-style: none;
}

body, html {
    width: 100%;
    min-height: 100%;
}

body {
    margin: 0;
    font-family: 'Europa', sans-serif;
    font-weight: $font__light;
    @include font_size_s;
    &.menu_open {
        overflow: hidden;
    }
}

// Smooth CSS transitions for buttons and links
a,
button,
input[type=submit] {
    transition-property: opacity, color, background-color, border;
    transition-duration: .25s;
}

a {
    text-decoration: none;
    &:hover {

    }
}

strong,
b {
    font-weight: $font__bold;
}

.makar_logo {
    background-image: url($images_dir + 'makar_logo_strap.svg');
    background-size: contain;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

.center {
    text-align: center;

    @include media_query(small) {
        text-align: left;
    }
}

.responsive_embed {
    position: relative;
    height: 0;
    &.ratio_16_9 {
        padding-bottom: 56.25%; // 16:9
    }
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

sup {
    vertical-align: super;
    font-size: 75%;
    line-height: 0;
}

sub {
    vertical-align: sub;
    font-size: 75%;
    line-height: 0;
}

figure {
  margin: 3em 0 0;
}
