@mixin small_caps {
	text-transform: uppercase;
	font-size: 22px;
	line-height: 28px;
	letter-spacing: 0.1em;

	@include media_query(small) {
		font-size: 10px;
		line-height: 15px;
	}
}

@mixin font_size_xxxs {
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.1em;

	@include media_query(small) {
		font-size: 10px;
		line-height: 15px;
	}
}

@mixin font_size_xxs {
	font-size: 18px;
	line-height: 21px;
	letter-spacing: 0.1em;

	@include media_query(small) {
		font-size: 10px;
		line-height: 15px;
	}
}

@mixin font_size_xs {
	font-size: 20px;
	line-height: 21px;
	letter-spacing: 0.05em;

	@include media_query(small) {
		font-size: 10px;
		line-height: 15px;
	}
}

@mixin font_size_s {
	font-size: 24px;
	line-height: 28.8px;

	@include media_query(small) {
		font-size: 12px;
		line-height: 16px;
	}
}

@mixin font_size_m {
	font-size: 30px;
	line-height: 34px;

	@include media_query(medium) {
		font-size: 24px;
		line-height: 28.8px;
	}

	@include media_query(small) {
		font-size: 16px;
		line-height: 20px;
	}
}

@mixin font_size_l {
	font-size: 42px;
	line-height: 52px;

	@include media_query(medium) {
		font-size: 32px;
		line-height: 40px;
	}

	@include media_query(small) {
		font-size: 22px;
		line-height: 28px;
	}
}
